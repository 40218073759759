import './index.scss'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import Swiper from 'swiper/js/swiper.min.js'
var ScreenSize = () => {
  var windowHeight = $(window).height()
  var windowWidth = $(window).width()
  var headerHeight = $("header .header").height()
  var bodyHeight = windowHeight - headerHeight
  if (windowWidth <= 1024) {
    $(".index-banner ul li").css("height", "auto")
  } else {
    $(".index-banner ul li").css("height", bodyHeight / 2)
  }
}
$(document).ready(() => {
  ScreenSize()
})
$(window).resize(() => {
  ScreenSize()
})

//鼠标移入移出视频播放暂停
$(".index-banner ul li").hover(function(){
  let video = $(this).find('video')
  setTimeout(() => {
    console.log('1111');
    $(video).trigger('play')
  },100);
},function(){
  let video = $(this).find('video')
  $(video).trigger('pause')
})

var xyfmswiper = new Swiper('.section2 .swiper-container', {
  speed: 800,
  slidesPerView: 1.3,
  spaceBetween: -20,
  centeredSlides: true,
  loop: true,
  breakpoints: {
    640: {
      slidesPerView: 1.9,
      spaceBetween: -30,
    },
    768: {
      slidesPerView: 1.9,
      spaceBetween: -30,
    },
    1024: {
      slidesPerView: 1.9,
      spaceBetween: -30,
    },
  },
  navigation: {
    nextEl: '.section2 .swiper-button-next',
    prevEl: '.section2 .swiper-button-prev',
  },
});
